<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0 hGoBack">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span>
        volver al listado
      </h2>
    </div>
    <b-card>
      <h3 class="h3CardTitle">Crear Equipo</h3>
      <hr />
      <!-- form -->
      <validation-observer ref="createAsset">
        <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row>
            <!-- col 1 -->
            <b-col sm="6" class="product_info">
              <b-form-group :label="'Categoría'" label-for="category">
                <v-select
                  v-model="parent_category"
                  label="name"
                  :filterable="true"
                  :searchable="true"
                  :options="selectCategoriesParent"
                  :placeholder="'Seleccionar Categoria'"
                />
              </b-form-group>
              <b-form-group
                :label="'Subcategoría'"
                label-for="product-subcategory"
              >
                <v-select
                  v-model="category"
                  label="name"
                  :options="selectCategories"
                  :filterable="false"
                  :searchable="false"
                  :placeholder="'Seleccionar subcategoría'"
                  :disabled="!parent_category"
                >
                </v-select>
              </b-form-group>

              <validation-provider
                #default="{ errors }"
                :name="'Product'"
                rules="required"
              >
                <b-form-group
                  v-if="hideProduct"
                  :label="'Producto'"
                  label-for="product"
                >
                  <v-select
                    ref="products"
                    v-model="product"
                    label="name"
                    :filterable="false"
                    :searchable="false"
                    :options="listProducts"
                    :placeholder="'Seleccionar producto'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <!-- location -->
              <validation-provider
                #default="{ errors }"
                :name="'Zona'"
                rules="required"
              >
                <b-form-group :label="'Zona'" label-for="location">
                  <v-select
                    v-model="location"
                    label="name"
                    :filterable="false"
                    :searchable="true"
                    :options="project.locations"
                    :placeholder="'Seleccionar Zona'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <!-- mac_address -->
              <validation-provider
                v-if="parent_category && parent_category.ref === 'cortinas'"
                #default="{ errors }"
                :name="'Mac Address'"
                rules="macAddressUnique"
              >
                <b-form-group :label="'Mac Address*'" label-for="product-mac">
                  <b-form-input
                    v-model="mac_address"
                    name="mac_address"
                    :placeholder="'Mac Address*'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <!-- client -->
              <validation-provider :name="'Cliente'" rules="required">
                <b-form-group :label="'Cliente'" label-for="cliente">
                  <v-select
                    v-model="client"
                    label="name"
                    ref="clients"
                    :placeholder="'Seleccionar Cliente'"
                    disabled
                    :clearable="false"
                  />
                </b-form-group>
              </validation-provider>
              <!-- project -->
              <validation-provider :name="'Obra'" rules="required">
                <b-form-group :label="'Obra'" label-for="location">
                  <v-select
                    v-model="project"
                    ref="projects"
                    label="name"
                    :placeholder="'Seleccionar Obra'"
                    disabled
                    :clearable="false"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- col 2 -->
            <b-col sm="6" class="product_info">
              <!-- description -->
              <b-form-group
                :label="'Descripción'"
                label-for="product-description"
              >
                <quill-editor v-model="description" />
              </b-form-group>

              <b-form-group :label="'Documentos'" label-for="documents">
                <ImageDropzone ref="documents" :files="files" maxFiles="1" />
              </b-form-group>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                type="submit"
                variant="primary"
                class="mt-2 mr-1 text-uppercase"
              >
                {{ "Añadir equipo" }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import vSelect from "vue-select";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
    ImageDropzone,
    BFormCheckbox,
    flatPickr,
  },
  data() {
    return {
      project_id: this.$route.params.project_id,
      name: "",
      mac_address: "",
      description: "",
      location: "",
      category: "",
      hideProduct: false,
      product: "",
      confirm: "",
      files: [],
      required,
      parent_category: "",
    };
  },
  computed: {
    ...mapGetters({
      project: "projects/getProject",
      selectCategories: "categories/getSelectCategories",
      selectCategoriesParent: "categories/getSelectCategoriesParent",
      selectProducts: "products/getSelectProductos",
    }),
    client() {
      return this.project.client;
    },
    listProducts () {
      const products = JSON.parse(JSON.stringify(this.selectProducts))  
      const formatName = products.map((product) => {
        product.name = `${product.name} - ${product.modelo}`
        return product
      })

      return this.parent_category.ref === 'cuadro-de-control'  ? formatName : this.selectProducts
    }
  },
  watch: {
    category() {
      if (this.category !== null) {
        this.getSelectProducts({
          page: "",
          per_page: 99999,
          search: "",
          calculator: false,
          type: "",
          category: this.category.id,
        });

        this.hideProduct = true;
      } else {
        this.product = "";
        this.$refs.products.options.splice(
          0,
          this.$refs.products.options.length
        );
        this.hideProduct = false;
      }
    },
    parent_category() {
      if (this.parent_category) {
        this.getSelectCategories({ parent: this.parent_category.id });
        this.category = "";
      }
    },
  },
  methods: {
    ...mapActions({
      create: "assets/create",
      getListClients: "clients/getListClients",
      getProject: "projects/getProject",
      getSelectCategories: "categories/getSelectCategories",
      getSelectCategoriesParent: "categories/getSelectCategories",
      getSelectProducts: "products/selectProducts",
    }),
    checkZones() {
      if (this.project.locations.length == 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Alerta",
            icon: "",
            text: "No existen Zonas para esta obra",
            variant: "danger",
          },
        });
      }
    },
    handleSubmit() {
      this.$refs.createAsset.validate().then((success) => {
        if (success) {
          const formData = this.createFormData();
          if (formData) {
            this.create({ asset: formData });
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end', 
          });
        }
      });
    },
    createFormData() {
      // const data = new FormData()
      const data = this.$refs.documents.getFormData("documents_asset");

      data.append("project_id", this.project.id);
      data.append("user_id", this.project.responsible.id);
      data.append("client_id", this.project.client.id);
      data.append("mac_address", this.mac_address);

      if (this.description !== "" || this.description !== null) {
        data.append("description", this.description);
      }

      if (this.product) {
        data.append("product_id", this.product.id);
        data.append("name", this.product.name);
      }

      if (this.location) {
        data.append("location_id", this.location.id);
      }

      return data;
    },
  },
  async created() {
    if (this.project_id !== undefined) {
      await this.getProject(this.project_id);
      await this.checkZones();
    }

    await this.getListClients({ page: 1, per_page: 9999 });
    await this.getSelectCategoriesParent({ parent: 0 });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
